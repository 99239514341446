<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
      >
        <el-form-item>
          <el-select
            style="width: 140px"
            v-model="formData.distributor_level_id"
            placeholder="经销商级别"
          >
            <el-option label="经销商级别" value="-1"></el-option>
            <el-option
              :label="item.name"
              :value="item.id"
              :key="index"
              v-for="(item, index) in distributor_level_list"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3"
              >身份证号</el-option
              >
              <el-option label="姓名" value="4">姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询</el-button
          >
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="onExplor()"-->
<!--          >导出</el-button-->
<!--          >-->
<!--        </el-form-item>-->
      </el-form>
    </div>

    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
      <!--      <el-table-column-->
      <!--        type="selection"-->
      <!--        align="center"-->
      <!--        header-align="center"-->
      <!--        width="40"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="id"
        label="#ID"
        width="100"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="member_info"
        label="经销商信息"
        width="300"
        header-align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.mobile
            "
          ></span>
          <span
            v-if="scope.row.member_level === '3'"
            v-html="
              scope.row.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.uname +
              '<br />' +
              '手机号码：' +
              scope.row.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.identity_number
            "
          ></span>

        </template>
      </el-table-column>
      <el-table-column
        prop="member_info"
        label="状态"
        width="80"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === '1'"
                  key=""
                  type="success">
            已激活
          </el-tag>
          <el-tag v-else
                  key=""
                  type="info">
            未激活
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="member_info"
        label="续会"
        width="80"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.xuhui === '1'"
                  key=""
                  type="success">
            已续会
          </el-tag>
          <el-tag v-else
                  key=""
                  type="info">
            未续会
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="distributor_level.name"
        label="店长级别"

        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="recommend_info"
        label="推荐人"

        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.recommend_info !== ''"
            v-html="
              scope.row.recommend_info.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.recommend_info.uname
            "
          ></span>
          <span v-else v-html="'-'"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="parent_info"
        label="安置人"

        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.parent_info !== ''"
            v-html="
              scope.row.parent_info.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.parent_info.uname +
              '<br />' +
              '安置位置：' +
              scope.row.net_postition_str
            "
          ></span>
          <span v-else v-html="'-'"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="注册时间"

        align="center"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
      :title="operateTitle"
      :visible.sync="editNetdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        :model="editNetformData"
        class="huiyuanxinxi"
        :rules="checkEditNetformRules"
        ref="editNetformData"
      >
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          prop=""
          v-show="editNetformData.member_level === '3'"
        >
          <div style="text-align: left">{{ editNetformData.uname }}</div>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="">
          <div style="text-align: left">{{ editNetformData.full_name }}</div>
        </el-form-item>
        <el-form-item
          label="推荐人编号"
          :label-width="formLabelWidth"
          prop="recommend_uname"
          v-show="editNetformData.type === 1"
          required
        >
          <el-input
            v-model="editNetformData.recommend_uname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="安置人编号"
          :label-width="formLabelWidth"
          prop="parent_uname"
          v-show="editNetformData.type === 2"
          required
        >
          <el-input
            v-model="editNetformData.parent_uname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="安置位置"
          :label-width="formLabelWidth"
          prop="net_postition"
          style="text-align: left"
          v-show="editNetformData.type === 2"
          required
        >
          <el-radio-group v-model="editNetformData.net_postition">
            <el-radio label="左"></el-radio>
            <el-radio label="右"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
          <el-input
            v-model="editNetformData.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editNetdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('editNetformData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- Form -->

    <el-dialog
      title="会员信息维护"
      :visible.sync="editMemberdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="editMemberformData"
        :rules="checkEditMemberformRules"
        ref="editMemberformData"
      >
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          prop=""
          v-show="editMemberformData.member_level === '3'"
        >
          <div style="text-align: left">{{ editMemberformData.uname }}</div>
        </el-form-item>
        <el-form-item
          label="昵称"
          :label-width="formLabelWidth"
          prop="nickname"
          required
        >
          <div style="text-align: left">{{ editMemberformData.nickname }}</div>
        </el-form-item>
        <el-form-item
          label="姓名"
          :label-width="formLabelWidth"
          prop="full_name"
          required
        >
          <div style="text-align: left">{{ editMemberformData.full_name }}</div>
        </el-form-item>
        <el-form-item
          label="手机号"
          :label-width="formLabelWidth"
          prop="mobile"
          required
        >
          <div style="text-align: left">{{ editMemberformData.mobile }}</div>
        </el-form-item>
        <el-form-item
          label="身份证号"
          :label-width="formLabelWidth"
          prop="identity_number"
          v-show="editMemberformData.member_level === '3'"
        >
          <div style="text-align: left">{{ editMemberformData.identity_number }}</div>
        </el-form-item>

        <el-form-item
          label="密码"
          :label-width="formLabelWidth"
          prop="password" v-show="false"
        >
          <el-input
            v-model="editMemberformData.password"
            placeholder="不修改密码留空即可"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否区域总监"
          :label-width="formLabelWidth"
          prop="is_leader"
          style="text-align: left"
          v-show="editMemberformData.member_level === '3'"
        >
          <el-radio-group v-model="editMemberformData.is_leader">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否市场督导"
          :label-width="formLabelWidth"
          prop="is_leader"
          style="text-align: left"
          v-show="editMemberformData.member_level === '3'"
        >
          <el-radio-group v-model="editMemberformData.is_leader_ship">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="editMemberformData.is_leader === '1'"
                      label="销售团队名称"
                      :label-width="formLabelWidth"
                      prop="leader_level"
                      style="text-align: left"
                      v-show="editMemberformData.member_level === '3'"
        >
          <el-input
            v-model="editMemberformData.leader_level"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item-->
        <!--            label="账号有效期开始"-->
        <!--            :label-width="formLabelWidth"-->
        <!--            prop="start_settle_mark"-->
        <!--        >-->
        <!--          <el-cascader-->
        <!--              v-model="editMemberformData.start_settle_mark"-->
        <!--              :options="start_settle_options"-->
        <!--              clearable-->
        <!--          ></el-cascader>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item-->
        <!--            label="账号有效期结束"-->
        <!--            :label-width="formLabelWidth"-->
        <!--            prop="end_settle_mark"-->
        <!--        >-->
        <!--          <el-cascader-->
        <!--              v-model="editMemberformData.end_settle_mark"-->
        <!--              :options="end_settle_options"-->
        <!--              clearable-->
        <!--          ></el-cascader>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item-->
        <!--          label="注册时间"-->
        <!--          :label-width="formLabelWidth"-->
        <!--          prop="created_at"-->
        <!--        >-->
        <div style="text-align: left">
          {{ editMemberformData.created_at }}
        </div>
        </el-form-item>
        <el-form-item
          label="最后登录时间"
          :label-width="formLabelWidth"
          prop="last_time"
        >
          <div style="text-align: left">{{ editMemberformData.last_time }}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editMemberdialogFormVisible = false"
        >取 消</el-button
        >
        <el-button type="primary" @click="doEditMember('editMemberformData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 批量维护 -->
    <el-dialog
      title="批量维护"
      :model="bitchFormData"
      :visible.sync="batch_manage"
      v-loading="loading"
    >
      <el-form class="huiyuanxinxi" ref="batch_manage">
        <el-form-item
          label="选中示例"
          :label-width="formLabelWidth"
          v-show="false"
        >
          <el-tag class="batch_manage_tags"> (直销商)会员编号，姓名 </el-tag>
          <el-tag type="success" class="batch_manage_tags">
            (VIP)手机号，姓名
          </el-tag>
        </el-form-item>
        <el-form-item
          label="当前选中"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 1"
        >
          <el-tag
            :type="item.member_level == '3' ? '' : 'success'"
            v-for="(item, index) in multipleSelection"
            :key="index"
            class="batch_manage_tags"
            :closable="multipleSelection.length > 1"
            :disable-transitions="true"
            @close="handleClose(item)"
          >
            <span v-if="item.member_level == '1'">
              {{ item.mobile }},{{ item.full_name }}
            </span>
            <span v-if="item.member_level == '3'">
              {{ item.uname }},{{ item.full_name }}
            </span>
          </el-tag>
        </el-form-item>
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          {{ bitchFormData.uname }}
        </el-form-item>
        <el-form-item
          label="范围规则"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          <el-select
            class="el-select-smid"
            v-model="bitchFormData.subtype"
            placeholder="请选择"
          >
            <el-option label="推荐网下全部" value="1"></el-option>
            <el-option label="安置网下全部" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="其他"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          <el-checkbox v-model="bitchFormData.isself"> 包含自身 </el-checkbox>
        </el-form-item>
        <el-form-item label="状态操作" :label-width="formLabelWidth">
          <el-switch
            class="switch custom batch_manage_switch"
            v-model="bitchFormData.restricted_login"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="限制登录"
            inactive-text="不限登录"
            active-color="#F56C6C"
          ></el-switch>
          <el-switch
            class="switch custom batch_manage_switch"
            v-model="bitchFormData.restricted_awards"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="冻结"
            inactive-text="未冻结"
            active-color="#F56C6C"
          ></el-switch>
          <el-switch
            class="switch custom batch_manage_switch"
            v-model="bitchFormData.restricted_activation"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="死点"
            inactive-text="非死点"
            active-color="#F56C6C"
          ></el-switch>
          <el-switch
            class="switch custom batch_manage_switch"
            v-model="bitchFormData.restricted_settlement"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="退出"
            inactive-text="未退出"
            active-color="#F56C6C"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batch_manage = false">取 消</el-button>
        <el-button type="primary" @click="doBatchManage()"> 确 定 </el-button>
      </div>
    </el-dialog>



    <el-dialog
      title="注册会员"
      :visible.sync="registerMemberdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="registerMemberformData"
        :rules="checkRegisterMemberformRules"
        ref="registerMemberformData"
      >
        <el-form-item label="推荐人" :label-width="formLabelWidth" prop="recommend_uname">
          <el-input
            placeholder="请输入推荐人编号"
            v-model="registerMemberformData.recommend_uname"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="安置人" :label-width="formLabelWidth" prop="net_parent_uname">
          <el-input
            placeholder="请输入安置人编号"
            v-model="registerMemberformData.net_parent_uname"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="安置区域" :label-width="formLabelWidth" prop="net_postition">
          <el-radio v-model="registerMemberformData.net_postition" label="1">左区</el-radio>
          <el-radio v-model="registerMemberformData.net_postition" label="2">右区</el-radio>
        </el-form-item>
        <el-form-item label="自动滑落" :label-width="formLabelWidth" prop="auto_sliding">
          <el-radio v-model="registerMemberformData.auto_sliding" label="0">否</el-radio>
          <el-radio v-model="registerMemberformData.auto_sliding" label="1">是</el-radio>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="full_name">
          <el-input
            placeholder="请输入姓名"
            v-model="registerMemberformData.full_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" :label-width="formLabelWidth" prop="identity_number">

          <el-input
            placeholder="请输入身份证号"
            v-model="registerMemberformData.identity_number"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              v-model="registerMemberformData.certificate_type"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="身份证" value="1">身份证</el-option>
              <el-option label="护照" value="2">护照</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth" prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="registerMemberformData.mobile"
            class="input-with-select"
          >
            <el-select
              style="width: 90px"
              v-model="registerMemberformData.phone_id"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option v-for="item in registerMemberformData.phoneList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">{{item.name}}</el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="registerMemberdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkRegister('registerMemberformData')">检 测</el-button>
        <!--        <el-button v-if="registerMemberformData.isCheck" type="primary" @click="doRegister('registerMemberformData')">确 定</el-button>-->
      </div>
    </el-dialog>



    <el-dialog
      title="请再次确认经销商信息"
      :visible.sync="registerMemberCheckDialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="registerMemberformData"
        :rules="checkRegisterMemberformRules"
      >
        <el-form-item label="推荐人信息" :label-width="formLabelWidth">
          {{registerMemberformData.recommend_uname}} {{registerMemberformData.recommend_full_name}}
        </el-form-item>
        <el-form-item label="安置人信息" :label-width="formLabelWidth">
          {{registerMemberformData.real_parent_uname}} {{registerMemberformData.real_parent_full_name}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="registerMemberCheckDialogFormVisible = false">不对，重新输入</el-button>
        <el-button v-if="registerMemberformData.isCheck" type="primary" @click="doRegister('registerMemberformData')">确认注册</el-button>
      </div>
    </el-dialog>


    <el-dialog
      title="导出会员"
      :visible.sync="explorMemberDialogFormVisible"
      v-loading="loading"

    >
      <el-form
        class="huiyuanxinxi"
        :model="formData"
        :rules="checkExplorMemberformRules"
        ref="formData"
      >
        <el-form-item
          label="查询方式"
          :label-width="formLabelWidth"
          ref="searchType"
          prop="searchType" v-show="false"
        >
          <el-select
            v-model="formData.searchType"
            clearable
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option label="条件查询" value="1">条件查询</el-option>
            <el-option label="导入查询" value="2">导入查询</el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.searchType === '1'" v-show="false"
                      label="注册时间"
                      :label-width="formLabelWidth"
                      ref="regTime"
                      prop="regTime"
        >
          <el-date-picker
            v-model="formData.regTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="formData.searchType === '1'" v-show="false"
                      label="支付(审核)时间"
                      :label-width="formLabelWidth"
                      ref="payTime"
                      prop="payTime"
        >
          <el-date-picker
            v-model="formData.payTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="formData.searchType === '1'" v-show="false"
                      label="结算时间"
                      :label-width="formLabelWidth"
                      ref="settleTime"
                      prop="settleTime"
        >
          <el-date-picker
            v-model="formData.settleTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="formData.searchType === '1' && false" v-show="false"
                      label="会员级别"
                      :label-width="formLabelWidth"
                      ref="memberType"
                      prop="memberType"
        >
          <el-checkbox-group v-model="formData.checkedMemberType1Arr">
            <el-checkbox v-for="city in formData.memberType1Arr" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-if="formData.searchType === '1'" v-show="false"
                      label="经销商级别"
                      :label-width="formLabelWidth"
                      ref="memberType"
                      prop="memberType"
        >
          <el-checkbox :indeterminate="formData.isIndeterminate" v-model="formData.checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="formData.checkedMemberTypeArr" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="city in formData.memberTypeArr" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          label="导出字段"
          :label-width="formLabelWidth"
          ref="column"
          prop="column"
        >
          <el-checkbox :indeterminate="formData.isIndeterminateCol" v-model="formData.checkAllCol" @change="handleCheckAllChangeCol">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="formData.checkedColumnArr" @change="handleCheckedCitiesChangeCol">
            <el-checkbox v-for="city in formData.columnArr" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-if="formData.checkedColumnArr.length > 0"
                      label="字段顺序"
                      :label-width="formLabelWidth"
                      ref="column"
                      prop="column"
        >
          <span v-for="city in formData.checkedColumnArr" :label="city" :key="city"> {{city}} </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="explorMemberDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doExplor()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { yearMonthData } from "@/util/yearMonthData";
export default {
  name: "DealerQuery",
  components: {},
  data() {
    return {
      test: true,
      agent_level_list: [],
      distributor_level_list: [],
      formData: {
        distributor_level_id: "-1",
        agent_level_id: "-1",
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        statusType: "1",
        statusValue: [],


        settleTime: [],
        payTime:[],
        regTime:[],
        isExport: 0,
        searchType: '1',
        timeType: '1',
        checkAll: false,
        memberType1Arr: ['VIP会员','经销商'],
        checkedMemberType1Arr: [],
        memberTypeArr: ['待审会员', '优惠顾客', '超级优惠顾客', '白金卡', '钻石卡', '至尊卡'],
        checkedMemberTypeArr: [],
        isIndeterminate: false,
        checkAllCol: false,
        // columnArr: ['会员编号','会员姓名', '手机号', '证件号', '会员级别', '经销商级别', '奖衔', '银行名称', '银行卡卡号', '开户名'],
        columnArr: ['会员编号','会员姓名','会员级别','会员身份证号','手机号','所属国家','昵称'],
        checkedColumnArr: [],
        isIndeterminateCol: false,
      },
      start_settle_options: yearMonthData.start,
      end_settle_options: yearMonthData.end,
      editNetformData: {
        id: "0",
        uname: "",
        full_name: "",
        recommend_uname: "",
        parent_uname: "",
        net_postition: "左",
        type: "",
        member_level: "",
        remark: "",
      },
      editMemberformData: {
        id: "0",
        uname: "",
        nickname: "",
        full_name: "",
        mobile: "",
        identity_number: "",
        status: "",
        is_black: "",
        is_leader: "",
        leader_level:"",
        password: "",
        start_settle_mark: "",
        end_settle_mark: "",
        is_leader_ship:""
      },
      checkEditNetformRules: {
        recommend_uname: [{ required: true, message: "请输入推荐人编号" }],
        parent_uname: [{ required: true, message: "请输入安置人编号" }],
      },
      checkEditMemberformRules: {
        nickname: [{ required: true, message: "请输入会员昵称" }],
        full_name: [{ required: true, message: "请输入会员姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      tableData: [],
      loading: false,
      operateTitle: "修改推荐人",
      formLabelWidth: "120px",
      editNetdialogFormVisible: false,
      editMemberdialogFormVisible: false,
      registerMemberdialogFormVisible: false,
      registerMemberCheckDialogFormVisible: false,
      explorMemberDialogFormVisible: false,
      multipleSelection: [],
      batch_manage: false,
      bitchFormData: {
        method: 1,
        member_id: [],
        uname: "",
        subtype: "",
        isself: false,
        restricted_login: 0,
        restricted_awards: 0,
        restricted_activation: 0,
        restricted_settlement: 0,
      },
      registerMemberformData:{},
      checkRegisterMemberformRules: {
        recommend_uname: [{ required: true, message: "请输入推荐人编号" }],
        net_parent_uname: [{ required: true, message: "请输入安置人编号" }],
        full_name: [{ required: true, message: "请输入姓名" }],
        identity_number: [{ required: true, message: "请输入身份证号" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      explorMemberformData:{

      },
      checkExplorMemberformRules: {

      }

    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberGetAgentList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            if(this.formData.isExport === 0){
              this.tableData = result.data.list;
              this.formData.total = parseInt(result.data.total);
            }else if(this.formData.isExport === 1){
              this.formData.isExport = 0;
              this.explorMemberDialogFormVisible = false;
              this.loading = false;
              this.myfun.myRouterAutoPush('/data/explor-task-list')
            }
          }

          this.loading = false;



        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    editNet(row, type) {
      this.operateTitle = type === 1 ? "修改推荐人" : "修改安置人";
      this.editNetformData.type = type;
      this.editNetformData.member_level = row.member_level;
      this.editNetformData.id = row.id;
      this.editNetformData.uname = row.uname;
      this.editNetformData.full_name = row.full_name;

      if (type === 1) {
        this.editNetformData.parent_uname = "111111";
        this.editNetformData.net_postition = "左";
      } else if (type === 2) {
        this.editNetformData.recommend_uname = "111111";
      }

      if (row.recommend_info !== "") {
        this.editNetformData.recommend_uname = row.recommend_info.uname;
      }

      if (row.parent_info !== "") {
        this.editNetformData.parent_uname = row.parent_info.uname;
        this.editNetformData.net_postition = row.net_postition_str;
      }

      this.editNetdialogFormVisible = true;
    },
    editMember(row) {
      this.editMemberformData.id = row.id;
      this.editMemberformData.member_level = row.member_level;
      this.editMemberformData.uname = row.uname;
      this.editMemberformData.created_at = row.created_at;
      this.editMemberformData.last_time = row.last_time;
      this.editMemberformData.nickname = row.nickname;
      this.editMemberformData.full_name = row.full_name;
      this.editMemberformData.mobile = row.mobile;
      this.editMemberformData.identity_number = row.identity_number;
      this.editMemberformData.is_leader = row.is_leader;
      this.editMemberformData.leader_level = row.leader_level;
      this.editMemberformData.is_leader_ship = row.is_leader_ship;
      this.editMemberformData.password = "";
      this.editMemberdialogFormVisible = true;
      this.editMemberformData.start_settle_mark = JSON.parse(row.start_settle_mark);
      this.editMemberformData.end_settle_mark = JSON.parse(row.end_settle_mark);
      // this.editFormData.end_settle_mark = JSON.parse(row.end_settle_mark);
    },
    doEditMember(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
            "adminMemberEditMember.api",
            this.editMemberformData,
            "post"
          )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.editMemberdialogFormVisible = false;
              }

              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName =
            this.editNetformData.type === 1
              ? "adminMemberEditMemberRecommend.api"
              : "adminMemberEditMemberParent.api";
          this.myfun.request(apiName, this.editNetformData)
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.editNetdialogFormVisible = false;
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },

    changeMemberStatus(value, id, index) {
      //验证通过执行请求
      this.loading = true;
      this.myfun.request(
        "adminMemberEditMember.api",
        {
          id: id,
          statusIndex: index,
          status: value,
          isModifyMemberStatus: 1,
        },
        "post"
      )
        .then((result) => {

          this.loading = false;
        });
    },
    statusManage(method = 1, row = {}) {
      this.bitchFormData.method = method;
      this.batch_manage = 1;
      if (this.bitchFormData.method == 2) {
        this.bitchFormData.uname = row.uname;
      }
    },
    doBatchManage() {
      if (this.bitchFormData.method == 1) {
        //批量复选
        if (this.multipleSelection.length < 1) {
          this.$message.warning("请选择要修改的会员");
        } else {
          let member_id = [];
          this.multipleSelection.forEach((item, key) => {
            member_id.push(item.id);
          });
          this.bitchFormData.member_id = member_id;
          //验证通过执行请求
          this.loading = true;
          this.myfun.request("adminMemberBitchMember.api", this.bitchFormData)
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.batch_manage = 0;
              } else {
                this.loading = false;
              }
            });
        }
      } else if (this.bitchFormData.method == "2") {
        if (this.bitchFormData.uname == "") {
          this.$message.warning("请填写会员编号");
          return;
        }
        if (this.bitchFormData.subtype == "") {
          this.$message.warning("请选择范围规则");
          return;
        }
        this.loading = true;
        this.myfun.request("adminMemberBitchMember.api", this.bitchFormData)
          .then((result) => {
            if (result.code === "1") {
              this.getList();
              this.batch_manage = 0;
            } else {
              this.loading = false;
            }
          });
      }
    },
    onRegister(){
      this.registerMemberdialogFormVisible = true;
    },
    checkRegister(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          //检测推荐人及安置人
          this.myfun.request('appMemberCheckRecommend.api', this.registerMemberformData)
            .then((result) => {
              if (result.code === "1") {
                this.registerMemberformData.recommend_full_name = result.data.full_name;
                this.myfun.request('appMemberCheckNetParent.api', this.registerMemberformData)
                  .then((result) => {
                    if (result.code === "1") {
                      this.registerMemberformData.real_parent_uname = result.data.uname;
                      this.registerMemberformData.real_parent_full_name = result.data.full_name;
                      this.registerMemberformData.isCheck = true;
                      this.registerMemberCheckDialogFormVisible = true;
                    }

                    this.loading = false;
                  });
              }else{
                this.loading = false;
              }

            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

    },
    doRegister(formName){
      this.loading = true;
      this.registerMemberCheckDialogFormVisible = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.registerMemberformData.nickname = this.registerMemberformData.full_name;
          // this.registerMemberformData.real_parent_uname = this.registerMemberformData.net_parent_uname;
          this.myfun.request('appMemberRegister.api', this.registerMemberformData)
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.registerMemberdialogFormVisible = false;
                this.resetForm();
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(){
      this.explorMemberformData = {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        settleTime: [],
        payTime:[],
        regTime:[],
        isExport: 0,
        searchType: '1',
        timeType: '1',
        checkAll: false,
        memberType1Arr: ['VIP会员','经销商'],
        checkedMemberType1Arr: [],
        memberTypeArr: ['待审会员', '优惠顾客', '超级优惠顾客', '白金卡', '钻石卡', '至尊卡'],
        checkedMemberTypeArr: [],
        isIndeterminate: false,
        checkAllCol: false,
        // columnArr: ['会员编号','会员姓名', '手机号', '证件号', '会员级别', '经销商级别', '奖衔', '银行名称', '银行卡卡号', '开户名'],
        columnArr: ['会员编号','会员姓名','会员级别','会员身份证号','手机号','所属国家','昵称'],
        checkedColumnArr: [],
        isIndeterminateCol: false,


      };

      this.registerMemberformData = {
        phone_id: "",
        phoneList:[],
        mobile: "",
        recommend_uname: "",
        recommend_full_name: "",
        net_parent_uname: "",
        net_postition: "1",
        member_level: 3,
        create_member_id: 0,
        full_name: "",
        identity_number:"",
        real_parent_uname: "",
        real_parent_full_name:"",
        nickname: "",
        password: 111111,
        isCheck: false,
        certificate_type:"1",
        auto_sliding:"0"
      };
      // this.$refs['formData'].resetFields()
    },
    handleCheckAllChange(val) {
      this.formData.checkedMemberTypeArr = val ? this.formData.memberTypeArr : [];
      this.formData.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.formData.checkAll = checkedCount === this.formData.memberTypeArr.length;
      this.formData.isIndeterminate = checkedCount > 0 && checkedCount < this.formData.memberTypeArr.length;
    },
    handleCheckAllChangeCol(val) {
      this.formData.checkedColumnArr = val ? this.formData.columnArr : [];
      this.formData.isIndeterminateCol = false;
    },
    handleCheckedCitiesChangeCol(value) {
      let checkedCount = value.length;
      this.formData.checkAllCol = checkedCount === this.formData.columnArr.length;
      this.formData.isIndeterminateCol = checkedCount > 0 && checkedCount < this.formData.columnArr.length;
    },
    //导出会员
    onExplor(){
      this.explorMemberDialogFormVisible = true;

    },
    //执行导出会员
    doExplor(){
      this.formData.isExport = 1;
      this.getList();
    }
  },
  created() {
    this.loading = true;
    this.myfun.request(
      "adminMemberGetMemberListDeputy.api").then((result) => {
      this.agent_level_list = result.data.agent_level_list;
      this.distributor_level_list = result.data.distributor_level_list;
      this.registerMemberformData.phoneList = result.data.phone_list;
      this.getList(1);
    })

    this.resetForm();
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.switshsdf {
  display: flex;
  flex-direction: column;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}

/* switch按钮样式 */
.el-switch {
  margin: 8px;
}
.switch.custom .el-switch__label--left span {
  margin-right: 5px;
}
.switch.custom .el-switch__label--right span {
  margin-left: 5px !important;
  margin-right: 20px !important;
}
.switch.custom .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch.custom .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch.custom .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch.custom .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch.custom .el-switch__label--left span {
  margin-left: 20px;
}
/*显示文字*/
.switch.custom .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.custom.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 82px !important;
  margin: 0;
}
.batch_manage_tags {
  margin-right: 10px;
}
.batch_manage_switch {
  margin-right: 10px;
}
</style>
