<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form :inline="true" ref="formData" :model="formData">
        <el-form-item>
          <el-select
            style="width: 250px"
            v-model="formData.country_id"
            placeholder="请选择国家/地区"
            @change="changeCountry"
          >
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name + '-' + item.name_en"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 130px"
            v-model="formData.searchType"
            placeholder="请选择"
          >
            <el-option label="选择来源" value="0">选择来源</el-option>
            <el-option label="来源订单" value="1">来源订单</el-option>
            <el-option label="手动操作" value="2">手动操作</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              class="el-select"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3"
                >身份证号</el-option
              >
              <el-option label="真实姓名" value="4">姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="warning"
            @click="createData()"
            v-if="myfun.checkAuthRule('adminMemberCreateDistributorLevelLog')"
          >
            新增数据
          </el-button>
        </el-form-item>
        <!--
      <el-form-item>
        <el-button type="primary" @click="importData()"> 数据导入 </el-button>
      </el-form-item>
	  -->
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        align="center"
        header-align="center"
        prop="id"
        label="#ID"
        width="50"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop=""
        label="国家/地区"
        width="150"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.country_info.name }}-{{
              scope.row.country_info.name_en
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="get_member"
        label="会员信息"
        width="320"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.member.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.member.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile
            "
          ></span>
          <span
            v-if="scope.row.member.member_level === '3'"
            v-html="
              scope.row.member.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.member.uname +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.member.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="get_distributor_level.name"
        label="变更的等级"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="from_str"
        label="来源类型"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="get_user.full_name"
        label="最近操作员"
        width="150"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.get_user !== ''"
            v-html="scope.row.get_user.full_name"
          ></span>
          <span
            v-if="scope.row.get_user === ''"
            v-html="'来源商城'"
            style="color: #cccccc"
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="start_time"
        label="开始时间"
        width="180"
      >
      </el-table-column>
<!--      <el-table-column -->
<!--        align="center"-->
<!--        header-align="center"-->
<!--        label="结束时间"-->
<!--        prop="end_time"-->
<!--        width="180"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.row.type == 2">-->
<!--            {{ scope.row.end_time | timeStrSubOneSecond }}-->
<!--          </span>-->
<!--          <span v-else>-->
<!--            {{ scope.row.end_time }}-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        align="center"
        header-align="center"
        prop="created_at"
        label="创建时间"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        label="操作"
      >
        <template class="annniuss" slot-scope="scope">
          <el-button
            @click="editData(scope.row)"
            style="margin: 2px 0"
            type="primary"
            size="small"
            plain
            v-if="
              myfun.checkAuthRule('adminMemberEditDistributorLevelLog') &&
              scope.row.type === '2'
            "
            >编辑</el-button
          >
          <br />
          <el-button
            @click="deleteData(scope.row)"
            type="danger"
            style="margin: 2px 0"
            size="small"
            v-if="
              myfun.checkAuthRule('adminMemberDeleteDistributorLevelLog') &&
              scope.row.type === '2'
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>
    <!-- Form -->
    <el-dialog
      title="新增信息"
      :visible.sync="formshow_create"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="createFormData"
        :rules="checkformRules"
        ref="formdata_create"
      >
        <el-form-item label="会员编号" :label-width="formLabelWidth" prop="uname">
          <el-input
            v-model="createFormData.uname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开始期数"
          :label-width="formLabelWidth"
          prop="start_settle_mark"
          required
        >
          <el-cascader
            v-model="createFormData.start_settle_mark"
            :options="start_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item v-show="false"
          label="结束期数"
          :label-width="formLabelWidth"
          prop="end_settle_mark"
        >
          <el-cascader
            v-model="createFormData.end_settle_mark"
            :options="end_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="直销商级别"
          :label-width="formLabelWidth"
          prop="distributor_level_id"
          required
        >
          <el-select
            class="el-select"
            v-model="createFormData.distributor_level_id"
            placeholder="请选择"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in distributorLevel_rs"
              :key="index"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          label="等级同步设置"
          :label-width="formLabelWidth"
          prop="tomember"
        >
          <el-checkbox v-model="createFormData.tomember">
            将当前等级同步至会员
          </el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formshow_create = false">取 消</el-button>
        <el-button type="primary" @click="doCreateData()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑信息"
      :visible.sync="formshow_edit"
      v-loading="loading"
    >
      <el-form
        :model="editFormData"
        :rules="checkformRules"
        ref="formdata_edit"
      >
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          prop="uname"
        >
          <div style="text-align: left">{{ editFormData.uname }}</div>
        </el-form-item>
        <el-form-item
          label="开始期数"
          :label-width="formLabelWidth"
          prop="start_settle_mark"
          required
        >
          <el-cascader
            v-model="editFormData.start_settle_mark"
            :options="start_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item v-show="false"
          label="结束期数"
          :label-width="formLabelWidth"
          prop="end_settle_mark"
        >
          <el-cascader
            v-model="editFormData.end_settle_mark"
            :options="end_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="直销商级别"
          :label-width="formLabelWidth"
          prop="distributor_level_id"
          required
        >
          <el-select
            class="el-select"
            v-model="editFormData.distributor_level_id"
            placeholder="请选择"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in distributorLevel_rs"
              :key="index"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          label="等级同步设置"
          :label-width="formLabelWidth"
          prop="tomember"
        >
          <el-checkbox v-model="editFormData.tomember">
            将当前等级同步至会员
          </el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formshow_edit = false">取 消</el-button>
        <el-button type="primary" @click="doEditData()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="数据导入"
      :visible.sync="formshow_import"
      v-loading="loading"
    >
      <el-form ref="formdata_import">
        <el-form-item label="导入模板" :label-width="formLabelWidth">
          <div style="text-align: left">
            <el-button type="text" @click="doImportData()">
              立即下载
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formshow_import = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { settleData } from "@/util/settleData";
export default {
  name: "MemberNoRepeatedValid",
  components: {},
  data() {
    return {
      test: true,
      countryList: [],
      formData: {
        country_id: "",
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        searchType: "0",
      },
      createFormData: {
        id: "0",
        uname: "",
        start_settle_mark: "",
        end_settle_mark: "",
        distributor_level_id: "",
        tomember: true,
      },
      editFormData: {
        id: "0",
        uname: "",
        start_settle_mark: "",
        end_settle_mark: "",
        distributor_level_id: "",
        tomember: true,
      },
      checkformRules: {
        uname: [{ required: true, message: "会员编号" }],
        start_settle_mark: [{ required: true, message: "开始期数" }],
        // end_settle_mark: [{ required: true, message: "结束期数" }],
        distributor_level_id: [{ required: true, message: "直销商等级" }],
      },
      tableData: [],
      distributorLevel_rs: [],
      loading: false,
      formLabelWidth: "120px",
      formshow_create: false,
      formshow_edit: false,
      formshow_import: false,
      start_settle_options: settleData.start,
      end_settle_options: settleData.end,
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request(
          "adminMemberGetDistributorLevelLogList.api",
          this.formData,
          "post"
        )
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.distributorLevel_rs = result.data.distributorLevel_rs;
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    createData() {
      this.formshow_create = true;
    },
    doCreateData() {
      this.$refs["formdata_create"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
              "adminMemberCreateDistributorLevelLog.api",
              this.createFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.formshow_create = false;
              } else {
                this.loading = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editData(row) {
      this.editFormData.id = row.id;
      this.editFormData.uname = row.member.uname;
      this.editFormData.start_settle_mark = JSON.parse(row.start_settle_mark);
      // this.editFormData.end_settle_mark = JSON.parse(row.end_settle_mark);
      this.editFormData.distributor_level_id = row.distributor_level_id;
      this.formshow_edit = true;
    },
    doEditData() {
      this.$refs["formdata_edit"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
              "adminMemberEditDistributorLevelLog.api",
              this.editFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.formshow_edit = false;
              } else {
            this.loading = false;
          }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
    deleteData(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.myfun.request(
              "adminMemberDeleteDistributorLevelLog.api",
              { id: row.id },
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
              } else {
            this.loading = false;
          }
            });
        })
        .catch(() => {
          this.$message.info('操作取消');
        });
    },
    importData() {
      this.formshow_import = true;
    },
    changeCountry() {
      this.getList();
    },
  },
  created() {
    this.loading = true;
    this.myfun.request("CommonPubGetCountryList.api").then((result) => {
        if (result.code === "1") {
          this.countryList = result.data;
          this.formData.country_id = this.countryList[0].id;
          this.getList(1);
        }
      });
  },

  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.annniuss .el-button {
  float: left;
}
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
</style>
