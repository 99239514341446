<template>
  <div class="mainwhite">
    <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
        style="text-align: right"
    >
      <el-form-item >
        <el-button type="primary" @click="uploadLeaderdialogFormVisible = true">导入策略委名单</el-button>
      </el-form-item>


    </el-form>


    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        border
        style="width: 100%; margin-top: 20px"
    >
      <el-table-column
          align="center"
          header-align="center"
          prop="策略委编号"
          label="策略委编号"
      >
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          prop="策略委头衔名称"
          label="策略委头衔名称"
      >
      </el-table-column>
    </el-table>


    <!-- Form -->

    <el-dialog
        title="导入策略委名单" :close-on-click-modal="false"
        :visible.sync="uploadLeaderdialogFormVisible"
        v-loading="loading"  style="width: 800px; margin: 0 auto"
    >
      <el-upload
          class="upload-demo" :on-success="upSuc"
          drag
          :action="uploadLeaderUrl"
          :auto-upload="false"
          :limit="uploadLimit"
          ref="uploadLeader"
          :data="uploadParams"
          accept=".xls,.xlsx"
          >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传*.xlsx、.xls文件，且不超过50M</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadLeaderdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()"
        >开始上传</el-button
        >
      </div>
    </el-dialog>

    <!-- Form -->
  </div>
</template>

<script>
export default {
  name: "MemberLeaderShipLevelManage",
  data() {
    return {
      activeName: "first",
      test: true,
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
      },
      tableData: [],
      loading: false,
      uploadLeaderdialogFormVisible: false,
      uploadLimit: 1,
      uploadParams: {},
      uploadLeaderUrl: '/mapi/'+process.env.VUE_APP_API_URL2+'adminMemberUploadLeaderdShip.api'
    };
  },
  methods: {
    submitUpload() {
      this.loading = true;
      this.$refs.uploadLeader.submit();
      // this.uploadParams = {};
    },
    upSuc(response, file, fileList){
      this.loading = false;
      if (response.code === "1") {
        this.$message.success('上传成功');
        this.uploadLeaderdialogFormVisible = false;
        this.myfun.myRouterAutoPush('/data/explor-task-list')
      }else{
        this.$refs.uploadLeader.clearFiles();
        this.$message.error(response.msg)
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberGetUploadLeaderdShipLastSetTaskInfo.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.import_export_excel_task_data;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },

    uploadLeader(){

    }
  },
  created() {
    this.uploadParams = this.myfun.getParams(this.uploadParams);
    this.getList(1);
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },

  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.el-form-item {
  margin-bottom: 0;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
</style>
