<template>
  <div class="mainwhite">
    <el-form v-loading="loading"
        :model="formData"
        class="huiyuanxinxi"
        :rules="checkFormDataRules"
        ref="formData"
    >
      <el-form-item
          label="查询方式"
          :label-width="formLabelWidth"
          ref="searchType"
          prop="searchType"
      >
        <el-select
            v-model="formData.searchType"
            clearable
            placeholder="请选择"
            style="width: 100%"
        >
          <el-option label="条件查询" value="1">条件查询</el-option>
          <el-option label="导入查询" value="2">导入查询</el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="formData.searchType === '2'" label="导入文件"
                    :label-width="formLabelWidth"
                    ref="file"
                    prop="file">
        <el-upload
            class="upload-demo" :on-success="upSuc" :before-upload="beforUp" :on-change="changeUp" :file-list="upFileList" :on-remove="removeUpFile"
            drag
            :action="uploadLeaderUrl"
            :auto-upload="false"
            :limit="uploadLimit"
            ref="uploadLeader"
            :data="uploadParams"
            accept=".xls,.xlsx"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传*.xlsx、.xls文件，且不超过50M</div>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="formData.searchType === '1'"
                    label="注册时间"
                    :label-width="formLabelWidth"
                    ref="regTime"
                    prop="regTime"
      >
        <el-date-picker
            v-model="formData.regTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="formData.searchType === '1'"
          label="支付(审核)时间"
          :label-width="formLabelWidth"
          ref="payTime"
          prop="payTime"
      >
        <el-date-picker
            v-model="formData.payTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="formData.searchType === '1'"
                    label="结算时间"
                    :label-width="formLabelWidth"
                    ref="settleTime"
                    prop="settleTime"
      >
        <el-date-picker
            v-model="formData.settleTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间">

        </el-date-picker>
      </el-form-item>
<!--      <el-form-item v-if="formData.searchType === '1'"-->
<!--                    label="会员级别"-->
<!--                    :label-width="formLabelWidth"-->
<!--                    ref="memberType"-->
<!--                    prop="memberType"-->
<!--      >-->
<!--        <el-checkbox-group v-model="formData.checkedMemberType1Arr">-->
<!--          <el-checkbox v-for="city in formData.memberType1Arr" :label="city" :key="city">{{city}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </el-form-item>-->
      <el-form-item v-if="formData.searchType === '1'"
                    label="经销商级别"
                    :label-width="formLabelWidth"
                    ref="memberType"
                    prop="memberType"
      >
        <el-checkbox :indeterminate="formData.isIndeterminate" v-model="formData.checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="formData.checkedMemberTypeArr" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in formData.memberTypeArr" :label="city" :key="city">{{city}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
                    label="导出字段"
                    :label-width="formLabelWidth"
                    ref="column"
                    prop="column"
      >
<!--        <el-checkbox :indeterminate="formData.isIndeterminateCol" v-model="formData.checkAllCol" @change="handleCheckAllChangeCol">全选</el-checkbox>-->
        <el-checkbox :indeterminate="formData.isRecIndeterminateCol" v-model="formData.checkRecAllCol" @change="handleRecCheckAllChangeCol">推荐</el-checkbox>
        <el-checkbox :indeterminate="formData.isParIndeterminateCol" v-model="formData.checkParAllCol" @change="handleParCheckAllChangeCol">安置</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="formData.checkedColumnArr" @change="handleCheckedCitiesChangeCol">
          <el-checkbox v-for="city in formData.columnArr" :label="city" :key="city">{{city}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="formData.checkedColumnArr.length > 0"
          label="字段顺序"
          :label-width="formLabelWidth"
          ref="column"
          prop="column"
      >
        <span v-for="city in formData.checkedColumnArr" :label="city" :key="city"> {{city}} </span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm()">重置</el-button>
      <el-button type="primary" @click="onSubmit()"
      >确定导出</el-button
      >
    </div>

  </div>
</template>

<script>
export default {
  name: "MemberLeaderSearch",
  data() {
    return {
      uploadParams:{},
      formData: {},
      uploadLimit: 1,
      uploadLeaderUrl: '/mapi/'+process.env.VUE_APP_API_URL2+'adminMemberMemberLeaderSearch.api',
      tableData: [],
      loading: false,
      checkFormDataRules:{
        // recommend_uname: [{ required: true, message: "请输入推荐人编号" }],
        // parent_uname: [{ required: true, message: "请输入安置人编号" }],
      },
      formLabelWidth: '150px',
      upFileList:[]
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.formData.checkedMemberTypeArr = val ? this.formData.memberTypeArr : [];
      this.formData.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.formData.checkAll = checkedCount === this.formData.memberTypeArr.length;
      this.formData.isIndeterminate = checkedCount > 0 && checkedCount < this.formData.memberTypeArr.length;
    },
    handleCheckAllChangeCol(val) {
      this.formData.checkedColumnArr = val ? this.formData.columnArr : [];
      this.formData.isIndeterminateCol = false;
    },



    handleRecCheckAllChangeCol(val) {
      if(val){
        this.formData.checkParAllCol = false;
        this.formData.isParIndeterminateCol = false;
      }

      let arrtmp = [];
      let recindex = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
      for (let i in this.formData.columnArr){
        if(this.myfun.in_array(i, recindex)){
          if(val){ //全选推荐
            arrtmp.push(this.formData.columnArr[i]);
          }
        }

      }
      this.formData.checkedColumnArr = val ? arrtmp : [];
      this.formData.isRecIndeterminateCol = false;
    },

    handleParCheckAllChangeCol(val) {
      if(val){
        this.formData.checkRecAllCol = false;
        this.formData.isRecIndeterminateCol = false;
      }

      let arrtmp = [];
      let recindex = [0,1,2,3,17,18,19,20,21,22,23,24,25,26,27,28,29];
      for (let i in this.formData.columnArr){
        if(this.myfun.in_array(i, recindex)){
          if(val){ //全选推荐
            arrtmp.push(this.formData.columnArr[i]);
          }
        }

      }
      this.formData.checkedColumnArr = val ? arrtmp : [];
      this.formData.isParIndeterminateCol = false;
    },


    handleCheckedCitiesChangeCol(value) {
      let checkedCount = value.length;
      this.formData.checkAllCol = checkedCount === this.formData.columnArr.length;
      this.formData.isIndeterminateCol = checkedCount > 0 && checkedCount < this.formData.columnArr.length;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberMemberLeaderSearch.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },
    resetForm(){
      this.formData = {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        settleTime: [],
        payTime:[],
        regTime:[],
        isExport: 0,
        searchType: '1',
        timeType: '1',
        checkAll: false,
        memberType1Arr: ['VIP会员','经销商'],
        checkedMemberType1Arr: [],
        memberTypeArr: ['待审会员', '优惠顾客', '超级优惠顾客', '白金卡', '钻石卡', '至尊卡'],
        checkedMemberTypeArr: [],
        isIndeterminate: false,
        isRecIndeterminateCol: false,
        isParIndeterminateCol: false,
        checkAllCol: false,
        checkRecAllCol: false,
        checkParAllCol: false,
        // columnArr: ['会员编号','会员姓名', '手机号', '证件号', '会员级别', '经销商级别', '奖衔', '银行名称', '银行卡卡号', '开户名'],
        columnArr: ['会员编号','会员姓名','会员级别','会员身份证号',
          '推荐人编号','推荐人姓名','推荐人级别',
          '上级推荐所属策略委编号','上级推荐所属策略委姓名','上级推荐所属策略委头衔名称',
          '上级推荐所属业务领导人编号','上级推荐所属业务领导人姓名','上级推荐所属业务领导人级别','上级推荐所属业务领导人所属团队','上级推荐所属业务领导人所属联创',
          '上级推荐所属销售团队领导人编号','上级推荐所属销售团队领导人姓名',
          '安置人编号','安置人姓名','安置人级别',
          '上级安置所属策略委编号','上级安置所属策略委姓名','上级安置所属策略委头衔名称',
          '上级安置所属业务领导人编号','上级安置所属业务领导人姓名','上级安置所属业务领导人级别','上级安置所属业务领导人所属团队','上级安置所属业务领导人所属联创',
          '上级安置所属销售团队领导人编号','上级安置所属销售团队领导人姓名'],
        checkedColumnArr: [],
        isIndeterminateCol: false,

      };
      // this.$refs['formData'].resetFields()
    },
    onSubmit(){
      this.loading = true;
      if(this.formData.checkedColumnArr.length <= 0){
        this.loading = false;
        this.$message.warning('请选择要导出的字段');
        return false;
      }


      if(this.formData.searchType === '2'){
        if(this.upFileList.length <= 0){
          this.loading = false;
          this.$message.warning('请选择要导入的文件');
          return false;
        }

        this.$forceUpdate();//强制更新 防止附加参数无法传递

        this.uploadParams = this.myfun.getParams(this.formData);
        this.$nextTick(() => {
          this.$refs.uploadLeader.submit();
        })

      }else{
        this.myfun.request("adminMemberMemberLeaderSearch.api", this.formData)
          .then((result) => {
            if (result.code === "1") {
              this.myfun.myRouterAutoPush('/data/explor-task-list')
            }
          });
      }

      this.loading = false;


    },
    upSuc(response, file, fileList){
      this.loading = false;
      if (response.code === "1") {
        this.$message.success('上传成功');
        this.myfun.myRouterAutoPush('/data/explor-task-list')
      }else{
        this.$refs.uploadLeader.clearFiles();
        // this.$refs.uploadLeader.abort();

        // this.$message.error(response.msg)
      }
    },
    beforUp(file){
    },
    changeUp(file, fileList){
      this.upFileList = fileList;
    },
    removeUpFile(file, fileList){
      this.upFileList = fileList;
    }
  },
  created() {
    this.resetForm();
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },

  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.el-form-item {
  margin-bottom: 0;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
</style>
