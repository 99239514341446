<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form :inline="true" ref="formData" :model="formData">
        <el-form-item>
          <el-select
            style="width: 250px"
            v-model="formData.country_id"
            placeholder="请选择国家/地区"
            @change="changeCountry"
          >
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name + '-' + item.name_en"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            class="el-select"
            v-model="formData.searchType"
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option label="来源" value="-1">全部</el-option>
            <el-option label="手动操作" value="0">手动操作</el-option>
            <el-option label="首购单" value="2">首购单</el-option>
            <el-option label="升级单" value="3">升级单</el-option>
            <el-option label="年度重消单" value="6">年度重消单</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              class="el-select"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3"
                >身份证号</el-option
              >
              <el-option label="真实姓名" value="4">真实姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="warning"
            @click="createData()"
            v-if="myfun.checkAuthRule('adminMemberCreateNoRepeatedValid')"
            >新增数据</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        align="center"
        header-align="center"
        prop="id"
        label="#ID"
        width="50"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop=""
        label="国家/地区"
        width="150"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.country_info.name }}-{{
              scope.row.country_info.name_en
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="get_member"
        label="会员信息"
        width="350"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.get_member.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.get_member.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.get_member.mobile
            "
          ></span>
          <span
            v-if="scope.row.get_member.member_level === '3'"
            v-html="
              scope.row.get_member.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.get_member.uname +
              '<br />' +
              '手机号码：' +
              scope.row.get_member.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.get_member.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="from_str"
        label="来源类型"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="get_user.full_name"
        label="最近操作员"
        width="200"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.get_user !== ''"
            v-html="scope.row.get_user.full_name"
          ></span>
          <span
            v-if="scope.row.get_user === ''"
            v-html="'来源商城'"
            style="color: #cccccc"
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="start_time"
        label="开始时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="end_time"
        label="结束时间"
        width="200"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0 || true">
            {{ scope.row.end_time | timeStrSubOneSecond }}
          </span>
          <span v-else>
            {{ scope.row.end_time }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="created_at"
        label="创建时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            @click="editData(scope.row)"
            style="margin: 2px 0"
            type="primary"
            plain
            size="small"
            v-if="
              myfun.checkAuthRule('adminMemberEditNoRepeatedValid') &&
              scope.row.type === '0'
            "
            >编辑</el-button
          >
          <br />
          <el-button
            @click="deleteData(scope.row)"
            type="danger"
            style="margin: 2px 0"
            size="small"
            v-if="
              myfun.checkAuthRule('adminMemberDeleteNoRepeatedValid') &&
              scope.row.type === '0'
            "
            >删除</el-button
          ><br />
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        style="margin-top: 1rem"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>
    <!-- Form -->
    <el-dialog
      title="新增信息"
      class="huiyuanxinxi"
      :visible.sync="formshow_create"
      v-loading="loading"
    >
      <el-form
        :model="createFormData"
        :rules="checkformRules"
        ref="formdata_create"
      >
        <el-form-item label="会员编号" :label-width="formLabelWidth" prop="uname">
          <el-input
            v-model="createFormData.uname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开始期数"
          :label-width="formLabelWidth"
          prop="start_settle_mark"
          required
        >
          <el-cascader
            v-model="createFormData.start_settle_mark"
            :options="start_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="结束期数"
          :label-width="formLabelWidth"
          prop="end_settle_mark"
          required
        >
          <el-cascader
            v-model="createFormData.end_settle_mark"
            :options="end_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="奖金选项" :label-width="formLabelWidth">
          <el-checkbox
            :indeterminate="createFormData.indeterminate"
            v-model="createFormData.bonus_all"
            @change="handleCheckAllCreateChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="createFormData.bonus"
            @change="handleCheckedCreateBonusChange"
          >
            <el-checkbox :label="item.key" v-for="(item, key) in bonus">{{
              item.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formshow_create = false">取 消</el-button>
        <el-button type="primary" @click="doCreateData()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑信息"
      :visible.sync="formshow_edit"
      v-loading="loading"
    >
      <el-form
        :model="editFormData"
        :rules="checkformRules"
        ref="formdata_edit"
      >
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          prop="uname"
        >
          <div style="text-align: left">{{ editFormData.uname }}</div>
        </el-form-item>
        <el-form-item
          label="开始期数"
          :label-width="formLabelWidth"
          prop="start_settle_mark"
          required
        >
          <el-cascader
            v-model="editFormData.start_settle_mark"
            :options="start_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="结束期数"
          :label-width="formLabelWidth"
          prop="end_settle_mark"
          required
        >
          <el-cascader
            v-model="editFormData.end_settle_mark"
            :options="end_settle_options"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="奖金选项" :label-width="formLabelWidth">
          <el-checkbox
            :indeterminate="editFormData.indeterminate"
            v-model="editFormData.bonus_all"
            @change="handleCheckAllEditChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="editFormData.bonus"
            @change="handleCheckedEditBonusChange"
          >
            <el-checkbox :label="item.key" v-for="(item, key) in bonus">{{
              item.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formshow_edit = false">取 消</el-button>
        <el-button type="primary" @click="doEditData()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { settleData } from "@/util/settleData";
export default {
  name: "MemberNoRepeatedValid",
  components: {},
  data() {
    return {
      test: true,
      countryList: [],
      formData: {
        country_id: "",
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        searchType: "-1",
      },
      createFormData: {
        id: "0",
        uname: "",
        start_settle_mark: "",
        end_settle_mark: "",
        indeterminate: false,
        bonus_all: 0,
        bonus: [],
      },
      editFormData: {
        id: "0",
        uname: "",
        start_settle_mark: "",
        end_settle_mark: "",
        indeterminate: false,
        bonus_all: 0,
        bonus: [],
      },
      bonus: [
        {
          key: "bonus_1",
          name: "推广奖",
        },
        {
          key: "bonus_2",
          name: "推荐奖",
        },
        {
          key: "bonus_3",
          name: "销售奖(量奖)",
        },
        {
          key: "bonus_4",
          name: "辅导奖",
        },
        {
          key: "bonus_5",
          name: "感恩奖",
        },
        {
          key: "bonus_6",
          name: "领导奖",
        },
      ],
      checkformRules: {
        uname: [{ required: true, message: "会员编号" }],
        start_settle_mark: [{ required: true, message: "开始期数" }],
        end_settle_mark: [{ required: true, message: "结束期数" }],
      },
      tableData: [],
      loading: false,
      formLabelWidth: "120px",
      formshow_create: false,
      formshow_edit: false,
      start_settle_options: settleData.start,
      end_settle_options: settleData.end,
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberGetNoRepeatedValidList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleCheckedCreateBonusChange(value) {
      let checkedCount = value.length;
      this.createFormData.bonus_all = checkedCount === this.bonus.length;
      this.createFormData.indeterminate =
        checkedCount > 0 && checkedCount < this.bonus.length;
    },
    handleCheckAllCreateChange(val) {
      let bonus_array = [];
      if (val) {
        this.bonus.forEach((item, key) => {
          bonus_array.push(item.key);
        });
      }
      this.createFormData.bonus = bonus_array;
      this.createFormData.indeterminate = false;
    },
    handleCheckedEditBonusChange(value) {
      let checkedCount = value.length;
      this.editFormData.bonus_all = checkedCount === this.bonus.length;
      this.editFormData.indeterminate =
        checkedCount > 0 && checkedCount < this.bonus.length;
    },
    handleCheckAllEditChange(val) {
      let bonus_array = [];
      if (val) {
        this.bonus.forEach((item, key) => {
          bonus_array.push(item.key);
        });
      }
      this.editFormData.bonus = bonus_array;
      this.editFormData.indeterminate = false;
    },
    createData() {
      this.formshow_create = true;
    },
    doCreateData() {
      this.$refs["formdata_create"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
              "adminMemberCreateNoRepeatedValid.api",
              this.createFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.formshow_create = false;
              } else {
            this.loading = false;
          }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editData(row) {
      this.editFormData.id = row.id;
      this.editFormData.uname = row.get_member.uname;
      this.editFormData.start_settle_mark = JSON.parse(row.start_settle_mark);
      this.editFormData.end_settle_mark = JSON.parse(row.end_settle_mark);
      this.editFormData.bonus = row.bonus_array;
      this.editFormData.bonus_all = row.bonus_all == "1" ? true : false;
      this.handleCheckedEditBonusChange(row.bonus_array);
      this.formshow_edit = true;
    },
    doEditData() {
      this.$refs["formdata_edit"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
              "adminMemberEditNoRepeatedValid.api",
              this.editFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.formshow_edit = false;
              } else {
            this.loading = false;
          }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
    deleteData(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.myfun.request(
              "adminMemberDeleteNoRepeatedValid.api",
              { id: row.id },
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
              } else {
            this.loading = false;
          }
            });
        })
        .catch(() => {
          this.$message.info('操作取消');
        });
    },
    changeCountry() {
      this.getList();
    },
  },
  created() {
    this.loading = true;
    this.myfun.request("CommonPubGetCountryList.api").then((result) => {
        if (result.code === "1") {
          this.countryList = result.data;
          this.formData.country_id = this.countryList[0].id;
          this.getList(1);
        }
      });
  },

  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}
</style>
