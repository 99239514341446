<template>
  <div class="mainwhite">
    <el-form
      :inline="true"
      ref="parentFormData"
      :model="parentFormData"
      class="demo-form-inline"
      style="text-align: left"
    >
      <el-form-item style="margin: 20px 10px"
        prop="start_settle_mark"
      >
        <el-cascader placeholder="期别"
                     v-model="parentFormData.start_settle_mark"
                     :options="start_settle_options"
                     clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item style="margin: 20px 0">
        <el-input
          placeholder="请输入内容"
          style="width: 300px"
          v-model="parentFormData.keywords"
          class="input-with-select"
        >
          <el-select
            class="el-select"
            v-model="parentFormData.searchField"
            slot="prepend"
            placeholder="请选择"
            style="width: 140px"
          >
            <el-option label="会员编号" value="1">会员编号</el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="margin: 20px 10px"
          type="primary"
          @click="onSubmit('parentFormData')"
        >查询</el-button>
      </el-form-item>
    </el-form>
    <div v-if="netLineList.length > 0">
      <ul class="lineList">
        <li>链路图:</li>
        <li v-for="(item, index) in netLineList" key="index">
          {{ item.uname }}  {{ item.full_name }}
        </li>
      </ul>
    </div>
    <el-table
      :data="tableData1"
      style="width: 100%"
      row-key="id"
      border
      lazy
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="uname" label="会员编号"  />
      <el-table-column prop="layer" label="代数"  />
      <el-table-column prop="full_name" label="姓名"  />
      <el-table-column prop="xuhui" label="续会状态">
        <template slot-scope="scope">
          <span
            v-if="scope.row.xuhui === '1'"
            v-html="'已续会'"
          ></span>
          <span
            v-else
            v-html="'未续会'"
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="推荐人">
        <template slot-scope="scope">
          <span
            v-html="
              '会员编号：' + scope.row.recommend_info.uname + ' <br> ' +
              '姓名：' + scope.row.recommend_info.full_name
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="distributor_level_str" label="级别"  />
      <el-table-column prop="my_week_pv" label="个人本期新增业绩"  />
      <el-table-column prop="last_settle_region_pv_balance" label="上级上期剩余业绩"  />
      <el-table-column prop="region_week_total_pv" label="上级本期新增业绩"  />
      <el-table-column prop="region_week_total_pv" label="上级本期实际业绩"  />
      <el-table-column prop="region_pv_balance" label="上级本期剩余业绩"  />
    </el-table>

  </div>
</template>

<script>
import { settleData } from "@/util/settleData";
import myfun from "../../util/myfun";

export default {
  name: "MemberNetSearch",
  components: {},
  data() {
    return {
      tableData1: [],
      showRecommendNet: true,
      showRecommendLine: false,
      recommendDefaultShowLevel: '',
      recommendDefaultExpandedKeys:[], //默认推荐展开节点
      recommentData:[],
      showParentNet: true,
      showParentLine: false,
      parentDefaultShowLevel: '',
      parentDefaultExpandedKeys:[],
      parentData:[],
      loading: false,
      logLoading: true,
      loadRecommendNodeLazy: true,
      loadParentNodeLazy: true,

      start_settle_options: settleData.start,
      end_settle_options: settleData.end,
      props: {
        label: "name",
        children: "zones",
        isLeaf: "leaf",
      },
      activeName: "first",

      //推荐人搜索框
      recommendFormData: {
        searchField: "1",
        keywords: "",
        node: {},
        resolve: {},
      },

      //安置人搜索框
      parentFormData: {
        searchField: "1",
        keywords: "",
        node: {},
        resolve: {},
        start_settle_mark: "",
        end_settle_mark: "",
        level: 0
      },

      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
      },

      tableData: [],

      addOrderDialogFormVisible: false,
      formLabelWidth: "120px",
      addOrderFormData: {},
      checkAddOrderFormDataRules: {
        full_name: [{ required: true, message: "请输入会员姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      netLineList: [],
      recommentLineList: [],


    };
  },
  methods: {
    load(row, treeNode, resolve) {
      // setTimeout(() => {
      //   resolve([
      //     {
      //       id: 31,
      //       date: '2016-05-01',
      //       name: 'wangxiaohu',
      //     },
      //     {
      //       id: 32,
      //       date: '2016-05-01',
      //       name: 'wangxiaohu',
      //     },
      //   ])
      // }, 1000)
      // let id = typeof treeNode.data !== "undefined" ? treeNode.data.id : 0;
      // let level = treeNode.level;
      // this.myfun.request(
      //   "adminMemberGetParentChildrenList.api",
      //   {
      //     id: id,
      //     level: level,
      //     keywords: this.parentFormData.keywords,
      //     searchField: this.parentFormData.searchField,
      //     start_settle_mark: this.parentFormData.start_settle_mark
      //   },
      //   "post"
      // )
      //   .then((result) => {
      //     if (result.code === "1") {
      //       let childrenList = result.data.list;
      //       for (let x in childrenList) {
      //         if (childrenList[x].leaf === "1") {
      //           childrenList[x].leaf = true;
      //         }
      //       }
      //       this.loading = false;
      //
      //       if(parseInt(this.parentDefaultShowLevel) > 0 && parseInt(this.parentDefaultShowLevel) > level){
      //         for(let i in childrenList){
      //           this.parentDefaultExpandedKeys.push(childrenList[i].id);
      //         }
      //       }
      //
      //       return resolve(childrenList);
      //     }else{
      //       this.loading = false;
      //       return resolve([]);
      //     }
      //   });
      console.log('adfsdfsfdsdf',treeNode)

      this.loadParentNode(treeNode, resolve, row)


    },
    loadParentNode(node, resolve, row) {
      let id = typeof row !== "undefined" ? row.id : 0;
      if (id === 0) {
        //加载数据的方法里把node，reslove存起来
        this.parentFormData.node = node;
        this.parentFormData.resolve = resolve;
        this.parentFormData.node.childNodes = [];
      }
      let level = this.parentFormData.level++;
      this.myfun.request(
        "adminMemberGetParentChildrenPvList.api",
        {
          id: id,
          level: level,
          keywords: this.parentFormData.keywords,
          searchField: this.parentFormData.searchField,
          start_settle_mark: this.parentFormData.start_settle_mark
        },
        "post"
      )
        .then((result) => {
          if (result.code === "1") {
            let childrenList = result.data.list;
            for (let x in childrenList) {
              if (childrenList[x].leaf === "1") {
                childrenList[x].hasChildren = false;
              }else{
                childrenList[x].hasChildren = true;
              }
            }
            this.loading = false;

            if(parseInt(this.parentDefaultShowLevel) > 0 && parseInt(this.parentDefaultShowLevel) > level){
              for(let i in childrenList){
                this.parentDefaultExpandedKeys.push(childrenList[i].id);
              }
            }

            console.log(childrenList)
            if(this.tableData1.length === 0){
              this.tableData1 = childrenList
            }else{
              return resolve(childrenList);
            }


          }else{
            this.loading = false;
            return resolve([]);
          }
        });
    },

    //重载查询树形图
    onSubmit(formName) {
      this.getNetLine()
      this.parentFormData.level = 0;
      this.tableData1 = [];
      this.loading = true;
      this.parentDefaultExpandedKeys = [];
      if(parseInt(this.parentDefaultShowLevel) > 10){
        this.$message.warning('最大代数不能超过10代')
        this.loading = false;
        return;
      }
      this.showParentNet = true;
      this.showParentLine = false;
      this.parentFormData.node.childNodes = [];
      this.loadParentNode(
        this.parentFormData.node,
        this.parentFormData.resolve
      );
    },

    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },

    getNetLine() {
      this.netLineList = [];
      if (this.parentFormData.keywords != "") {
        this.myfun
          .request(
            "adminMemberGetNetParentLineList.api",
            {
              searchField: this.parentFormData.searchField,
              keywords: this.parentFormData.keywords,
            },
            "post"
          )
          .then((res) => {
            if (res.code == "1") {
              this.netLineList = res.data.line_rs;
            }
          });
      }
    },
    toUrl(command){
      myfun.myRouterAutoPush(command)
    },
    mytest() {
      console.log("安置debug:");
      console.log(this.parentFormData);
      console.log("推荐debug:");
      console.log(this.recommendFormData);
    },
    toSearch(type, uname){
      if(uname === '无'){
        // this.$message.warning();
        return;
      }
      if(type === 1){
        this.recommendFormData.keywords = uname
        this.onSubmit('recommendFormData')
      }else if(type === 2){
        this.parentFormData.keywords = uname
        this.onSubmit('parentFormData')
      }
    }
  },
  created() {
    // let date = new Date();
    // this.addOrderFormData.pay_time=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    // console.log(this.addOrderFormData.pay_time);
    // console.log('aaa',date);
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.wangluogreen {
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.wangluohui {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluored {
  padding: 5px 10px;
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluoyellow {
  padding: 5px 10px;
  color: #0099CC;
  background: #99CCFF;
  border-color: #fbc4c4;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluo {
  padding: 5px 10px;
  background: #ecf5ff;
  color: #409eff;
  border-color: #b3d8ff;
  border-radius: 4px;
  margin: 0 10px;
}
.el-tree-node__content {
  height: 50px !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  min-height: 800px;
}

.lineList {
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
}
.lineList li {
  list-style: none;
  padding: 10px 5px;
  font-size: 14px;
}
.lineList li:nth-child(n + 3):before {
  content: " > ";
}
.el-form-item {
  margin-bottom: 0;
}
.el-table th,
.el-table tr {
  background-color: transparent !important;
}
</style>
