<template>
  <div class="mainwhite">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="推荐网络" name="first" v-loading="loading">
        <el-tabs tab-position="right">
          <el-tab-pane label="树形图">
            <el-form
              :inline="true"
              ref="recommendFormData"
              :model="recommendFormData"
              class="demo-form-inline"
              style="text-align: left"
            >
              <el-form-item style="margin: 20px 0">
                <el-input
                  placeholder="请输入内容"
                  v-model="recommendFormData.keywords"
                  class="input-with-select"
                  style="width: 500px"
                >
                  <el-select
                    v-model="recommendFormData.searchField"
                    slot="prepend"
                    placeholder="请选择"
                    style="width: 140px"
                  >
                    <el-option label="会员编号" value="1">会员编号</el-option>
                    <!--                    <el-option label="手机号" value="2">手机号</el-option>-->
                    <!--                    <el-option label="身份证号" value="3">身份证号</el-option>-->
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item style="margin: 20px 10px">
                <el-input placeholder="代" v-model="recommendDefaultShowLevel"></el-input>
              </el-form-item>
              <el-form-item style="margin: 20px 10px">
                <el-button type="primary" @click="onSubmit('recommendFormData')"
                  >查询下级</el-button
                >
              </el-form-item>
              <el-form-item style="margin: 20px 10px">
                <el-button type="primary" @click="getRecommentLine()"
                >查询上级</el-button
                >
              </el-form-item>
            </el-form>
            <el-tree v-show="showRecommendNet"
              :props="props"
              :load="loadRecommendNode"
              :lazy="loadRecommendNodeLazy"
              :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="recommendDefaultExpandedKeys"
            >
              <span style="font-size: 14px" slot-scope="{ node, data }">
                <span class="wangluored">{{ data.layer }}</span>
                <span class="wangluo">#{{ data.id }}</span>
                <span style="padding-left: 20px">|</span>
                <span style="padding-left: 20px">会员编号：{{ data.uname }}</span>
                <span style="padding-left: 20px"
                  >姓名：{{ data.full_name }}</span
                >
                <span style="padding-left: 10px">|</span>
                <span class="wangluogreen" v-if="data.status === '1'">已激活</span>
                <span class="wangluohui" v-else >未激活</span>
                <span class="wangluogreen" v-if="data.xuhui === '1'">已续会</span>
                <span class="wangluohui" v-else >未续会</span>
                <span style="padding-left: 10px">|</span>
                <span class="wangluored">{{ data.distributor_level_str }}</span>
                <span style="padding-left: 10px">|</span>
                <span class="wangluo" @click="toSearch(1,data.recommend_info.uname)">{{data.recommend_info.uname}}丨{{data.recommend_info.full_name}}(推荐人)</span>
                <span class="wangluo" @click="toSearch(1,data.net_parent_info.uname)">{{data.net_parent_info.uname}}丨{{data.net_parent_info.full_name}}(服务人)</span>
                <span style="padding-left: 10px; padding-right: 10px">|</span>
                <el-dropdown @command="toUrl">
                  <el-button type="primary" size="small">
                    查询更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" style="width: 100px" @click="toUrl">
                    <el-dropdown-item command="/member/list">个人信息</el-dropdown-item>
                    <el-dropdown-item command="/settle/member-pv-search">业绩查询</el-dropdown-item>
                    <el-dropdown-item command="/settle/member-repeat-pv-search">复消查询</el-dropdown-item>
                    <el-dropdown-item command="/order/list">订单详情</el-dropdown-item>
                    <el-dropdown-item>奖金明细</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
<!--                <span class="wangluohui">{{ data.agent_level_str }}</span>-->
<!--                <span style="padding-left: 20px">|</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader === '1'">{{data.leader_info.leader_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader_ship === '1'">{{data.leader_ship_info.leader_ship_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_business_leader === '1' && data.is_big_business_leader === '1'">{{data.business_leader_team}} {{data.business_leader_cofounder}}</span>-->

              </span>
            </el-tree>
            <el-tree v-show="showRecommendLine"
                :data="recommentData"
                     :default-expand-all="true"
                     :lazy="false"
            >
              <span style="font-size: 14px" slot-scope="{ node, data }">
                <span class="wangluored">{{ data.layer }}</span>
                <span class="wangluo">#{{ data.id }}</span>
                <span style="padding-left: 20px">|</span>
                <span style="padding-left: 20px">会员编号：{{ data.uname }}</span>
                <span style="padding-left: 20px"
                >姓名：{{ data.full_name }}</span
                >
                <span class="wangluogreen">{{ data.net_postition_str }}</span>


<!--                <span style="padding-left: 20px">|</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader === '1'">{{data.leader_info.leader_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader_ship === '1'">{{data.leader_ship_info.leader_ship_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_business_leader === '1' && data.is_big_business_leader === '1'">{{data.business_leader_team}} {{data.business_leader_cofounder}}</span>-->
              </span>
            </el-tree>
          </el-tab-pane>
          <!-- <el-tab-pane label="结构图">结构图</el-tab-pane> -->
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="安置网络" name="second" v-loading="loading">
        <el-tabs tab-position="right">
          <el-tab-pane label="树形图">
            <el-form
              :inline="true"
              ref="parentFormData"
              :model="parentFormData"
              class="demo-form-inline"
              style="text-align: left"
            >
              <el-form-item style="margin: 20px 0">
                <el-input
                  placeholder="请输入内容"
                  style="width: 500px"
                  v-model="parentFormData.keywords"
                  class="input-with-select"
                >
                  <el-select
                    class="el-select"
                    v-model="parentFormData.searchField"
                    slot="prepend"
                    placeholder="请选择"
                    style="width: 140px"
                  >
                    <el-option label="会员编号" value="1">会员编号</el-option>
                    <!--                    <el-option label="手机号" value="2">手机号</el-option>-->
                    <!--                    <el-option label="身份证号" value="3">身份证号</el-option>-->
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item style="margin: 20px 10px">
                <el-input placeholder="代" v-model="parentDefaultShowLevel"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="margin: 20px 10px"
                  type="primary"
                  @click="onSubmit('parentFormData')"
                  >查询下级</el-button
                >
              </el-form-item>
              <el-form-item style="margin: 20px 10px">
                <el-button type="primary" @click="getNetLine()"
                >查询上级</el-button
                >
              </el-form-item>
            </el-form>
            <el-tree v-show="showParentNet"
              :props="props"
              :load="loadParentNode"
              lazy
              :expand-on-click-node="false"
              node-key="id"
              :default-expanded-keys="parentDefaultExpandedKeys"
            >
              <span style="font-size: 14px" slot-scope="{ node, data }">
                <span class="wangluored">{{ data.layer }}</span>
                <span class="wangluo">#{{ data.id }}</span>
                <span style="padding-left: 20px">|</span>
                <span style="padding-left: 20px">会员编号：{{ data.uname }}</span>
                <span style="padding-left: 20px"
                  >姓名：{{ data.full_name }}</span
                >
                <span class="wangluogreen">{{ data.net_postition_str }}</span>

                <span style="padding-left: 10px">|</span>
                <span class="wangluogreen" v-if="data.status === '1'">已激活</span>
                <span class="wangluohui" v-else >未激活</span>
                <span class="wangluogreen" v-if="data.xuhui === '1'">已续会</span>
                <span class="wangluohui" v-else >未续会</span>
                <span style="padding-left: 10px">|</span>
                <span class="wangluored">{{ data.distributor_level_str }}</span>
                <span style="padding-left: 10px">|</span>
                <span class="wangluo" @click="toSearch(2,data.recommend_info.uname)">{{data.recommend_info.uname}}丨{{data.recommend_info.full_name}}(推荐人)</span>
                <span class="wangluo" @click="toSearch(2,data.net_parent_info.uname)">{{data.net_parent_info.uname}}丨{{data.net_parent_info.full_name}}(服务人)</span>
                <span style="padding-left: 10px">|</span>


<!--                <span class="wangluohui">{{ data.agent_level_str }}</span>-->
<!--                <span style="padding-left: 20px">|</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader === '1'">{{data.leader_info.leader_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader_ship === '1'">{{data.leader_ship_info.leader_ship_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_business_leader === '1' && data.is_big_business_leader === '1'">{{data.business_leader_team}} {{data.business_leader_cofounder}}</span>-->
              </span>
            </el-tree>
            <el-tree v-show="showParentLine"
                     :data="parentData"
                     :default-expand-all="true"
                     :lazy="false"
            >
              <span style="font-size: 14px" slot-scope="{ node, data }">
                <span class="wangluored">{{ data.layer }}</span>
                <span class="wangluo">#{{ data.id }}</span>
                <span style="padding-left: 20px">|</span>
                <span style="padding-left: 20px">会员编号：{{ data.uname }}</span>
                <span style="padding-left: 20px"
                >姓名：{{ data.full_name }}</span
                >
<!--                <span style="padding-left: 20px">|</span>-->
<!--                <span class="wangluohui">{{ data.distributor_level_str }}</span>-->
<!--                <span class="wangluohui">{{ data.agent_level_str }}</span>-->
<!--                <span style="padding-left: 20px">|</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader === '1'">{{data.leader_info.leader_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_leader_ship === '1'">{{data.leader_ship_info.leader_ship_level}}</span>-->
<!--                <span class="wangluohui" v-if="data.is_business_leader === '1' && data.is_big_business_leader === '1'">{{data.business_leader_team}} {{data.business_leader_cofounder}}</span>-->
              </span>
            </el-tree>
          </el-tab-pane>
          <!-- <el-tab-pane label="结构图">结构图</el-tab-pane> -->
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="调整日志" name="three">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%; margin-top: 20px"
          v-loading="logLoading"
        >
          <el-table-column
            prop="id"
            label="#ID"
            width="100"
            align="center"
            header-align="center"
          >
          </el-table-column>

          <el-table-column
            prop="member_info"
            label="会员信息"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <tr
                v-html="
                  '会员编号：' +
                  scope.row.member.uname +
                  '<br />' +
                  '真实姓名：' +
                  scope.row.member.full_name +
                  '<br />'
                "
              ></tr>
            </template>
          </el-table-column>

          <el-table-column
            prop="member_info"
            label="推荐人"
            width="200"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <tr
                v-if="scope.row.type === '1'"
                v-html="
                  '新推荐人编号：' +
                  scope.row.recommend_info.uname +
                  '<br />' +
                  '新推荐人姓名：' +
                  scope.row.recommend_info.full_name +
                  '<br />' +
                  '原推荐人编号：' +
                  scope.row.old_recommend_info.uname +
                  '<br />' +
                  '原推荐人姓名：' +
                  scope.row.old_recommend_info.full_name +
                  '<br />'
                "
              ></tr>
              <tr v-else>
                -
              </tr>
            </template>
          </el-table-column>

          <el-table-column
            prop="member_info"
            label="安置人"
            width="200"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <tr
                v-if="scope.row.type === '2'"
                v-html="
                  '新安置人编号：' +
                  scope.row.parent_info.uname +
                  '<br />' +
                  '新安置人姓名：' +
                  scope.row.parent_info.full_name +
                  '<br />' +
                  '原安置人编号：' +
                  scope.row.old_parent_info.uname +
                  '<br />' +
                  '原安置人姓名：' +
                  scope.row.old_parent_info.full_name +
                  '<br />'
                "
              ></tr>
              <tr v-else>
                -
              </tr>
            </template>
          </el-table-column>

          <el-table-column
            prop="member_info"
            label="安置位置"
            width="200"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <tr
                v-if="scope.row.type === '2'"
                v-html="
                  '新安置位置：' +
                  scope.row.net_postition_str +
                  '<br />' +
                  '原安置位置：' +
                  scope.row.old_net_postition_str +
                  '<br />'
                "
              ></tr>
              <tr v-else>
                -
              </tr>
            </template>
          </el-table-column>
          <el-table-column
            prop="status_str"
            label="状态"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="user.full_name"
            label="操作人"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="时间"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="center"
            header-align="center"
          >
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            style="margin-top: 1rem"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="formData.page"
            :page-sizes="pageSizs"
            :page-size="formData.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="formData.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- Form -->

    <el-dialog
      title="新增订单"
      :visible.sync="addOrderDialogFormVisible"
      v-loading="loading"
    >
      <el-form
        :model="addOrderFormData"
        :rules="checkAddOrderFormDataRules"
        ref="addOrderFormData"
      >
        <el-form-item
          label="订单类型"
          :label-width="formLabelWidth"
          prop="orderTypeId"
          style="text-align: left"
        >
          <el-select
            v-model="addOrderFormData.orderTypeVal"
            placeholder="请选择"
          >
            <el-option
              v-for="item in addOrderFormData.orderTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="PV"
          :label-width="formLabelWidth"
          prop="total_pv"
          required
        >
          <el-input
            v-model="addOrderFormData.total_pv"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="金额"
          :label-width="formLabelWidth"
          prop="pay_amount"
          required
        >
          <el-input
            v-model="addOrderFormData.pay_amount"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="支付时间"
          :label-width="formLabelWidth"
          prop="pay_time"
          required
          style="text-align: left"
        >
          <el-date-picker
            v-model="addOrderFormData.pay_time"
            :default-value="new Date()"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addOrderDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOrder('addOrderFormData')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import myfun from "../../util/myfun";

export default {
  name: "MemberNetSearch",
  components: {},
  data() {
    return {
      showRecommendNet: true,
      showRecommendLine: false,
      recommendDefaultShowLevel: '',
      recommendDefaultExpandedKeys:[], //默认推荐展开节点
      recommentData:[],
      showParentNet: true,
      showParentLine: false,
      parentDefaultShowLevel: '',
      parentDefaultExpandedKeys:[],
      parentData:[],
      loading: true,
      logLoading: true,
      loadRecommendNodeLazy: true,
      loadParentNodeLazy: false,
      props: {
        label: "name",
        children: "zones",
        isLeaf: "leaf",
      },
      activeName: "first",

      //推荐人搜索框
      recommendFormData: {
        searchField: "1",
        keywords: "",
        node: {},
        resolve: {},
      },

      //安置人搜索框
      parentFormData: {
        searchField: "1",
        keywords: "",
        node: {},
        resolve: {},
      },

      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
      },

      tableData: [],

      addOrderDialogFormVisible: false,
      formLabelWidth: "120px",
      addOrderFormData: {},
      checkAddOrderFormDataRules: {
        full_name: [{ required: true, message: "请输入会员姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      netLineList: [],
      recommentLineList: [],
    };
  },
  methods: {
    loadRecommendNode(node, resolve) {
      let id = typeof node.data !== "undefined" ? node.data.id : 0;
      if (id === 0) {
        //加载数据的方法里把node，reslove存起来
        this.recommendFormData.node = node;
        this.recommendFormData.resolve = resolve;
        this.recommendFormData.node.childNodes = [];
      }
      let level = node.level;



      this.myfun.request(
          "adminMemberGetRecommendChildrenList.api",
          {
            id: id,
            level: level,
            keywords: this.recommendFormData.keywords,
            searchField: this.recommendFormData.searchField,
          },
          "post", false
        )
        .then((result) => {
          if (result.code === "1") {
            let childrenList = result.data.list;
            for (let x in childrenList) {
              if (childrenList[x].leaf === "1") {
                childrenList[x].leaf = true;
              }
            }
            this.loading = false;


            if(parseInt(this.recommendDefaultShowLevel) > 0 && parseInt(this.recommendDefaultShowLevel) > level){
              for(let i in childrenList){
                this.recommendDefaultExpandedKeys.push(childrenList[i].id);
              }
            }

            return resolve(childrenList);
          }else{
            this.loading = false;
            return resolve([]);
          }


        });
    },
    loadParentNode(node, resolve) {
      if (!this.loadParentNodeLazy) {
        //加载数据的方法里把node，reslove存起来
        this.parentFormData.node = node;
        this.parentFormData.resolve = resolve;
        this.parentFormData.node.childNodes = [];
        return;
      }
      let id = typeof node.data !== "undefined" ? node.data.id : 0;
      let level = node.level;
      this.myfun.request(
          "adminMemberGetParentChildrenList.api",
          {
            id: id,
            level: level,
            keywords: this.parentFormData.keywords,
            searchField: this.parentFormData.searchField,
          },
          "post"
        )
        .then((result) => {
          if (result.code === "1") {
            let childrenList = result.data.list;
            for (let x in childrenList) {
              if (childrenList[x].leaf === "1") {
                childrenList[x].leaf = true;
              }
            }
            this.loading = false;

            if(parseInt(this.parentDefaultShowLevel) > 0 && parseInt(this.parentDefaultShowLevel) > level){
              for(let i in childrenList){
                this.parentDefaultExpandedKeys.push(childrenList[i].id);
              }
            }

            return resolve(childrenList);
          }else{
            this.loading = false;
            return resolve([]);
          }
        });
    },
    handleClick(tab, event) {
      // console.log(tab.label, tab.index)
      // console.log(tab, event);
      if (tab.index === "1") {
        //手动调用加载数据方法
        if (!this.loadParentNodeLazy) {
          this.loading = true;
          this.loadParentNodeLazy = true;
          this.loadParentNode(
            this.parentFormData.node,
            this.parentFormData.resolve
          );
        }
      }

      if (tab.index === "2") {
        this.getMemberNetModifyLog();
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getMemberNetModifyLog();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getMemberNetModifyLog();
    },
    getMemberNetModifyLog() {
      this.logLoading = true;
      this.myfun.request("adminMemberGetMemberNetModifyLog.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.logLoading = false;
        });
    },
    //重载查询树形图
    onSubmit(formName) {
      this.loading = true;
      this.recommendDefaultExpandedKeys = [];
      this.parentDefaultExpandedKeys = [];
      if (formName === "parentFormData") {
        if(parseInt(this.parentDefaultShowLevel) > 10){
          this.$message.warning('最大代数不能超过10代')
          this.loading = false;
          return;
        }
        this.showParentNet = true;
        this.showParentLine = false;
        this.parentFormData.node.childNodes = [];
        this.loadParentNode(
          this.parentFormData.node,
          this.parentFormData.resolve
        );
      } else if (formName === "recommendFormData") {
        if(parseInt(this.recommendDefaultShowLevel) > 10){
          this.$message.warning('最大代数不能超过10代')
          this.loading = false;
          return;
        }
        this.showRecommendNet = true;
        this.showRecommendLine = false;
        this.recommendFormData.node.childNodes = [];
        this.loadRecommendNode(
          this.recommendFormData.node,
          this.recommendFormData.resolve
        );
      }
    },

    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    resetFormData(formName) {
      this[formName] = {
        member_id: 0,
        total_pv: 500,
        pay_amount: 500,
        pay_time: new Date(),
        orderTypeVal: "首购单",
        orderTypeList: [
          {
            value: "1",
            label: "重消单",
          },
          {
            value: "2",
            label: "首购单",
          },
          {
            value: "3",
            label: "升级单",
          },
          {
            value: "4",
            label: "体验包",
          },
          {
            value: "5",
            label: "超级体验包",
          },
          {
            value: "6",
            label: "全年重消单",
          },
          {
            value: "7",
            label: "体外单",
          },
          {
            value: "8",
            label: "激活单",
          },
          {
            value: "9",
            label: "续约单",
          },
        ],
      };

      console.log();
      // this.addOrderFormData.pay_time = '';
    },

    //新增订单
    addOrder(row) {
      this.resetFormData("addOrderFormData");
      this.addOrderFormData.member_id = row.id;
      // this..member_level = row.member_level;
      // this..uname = row.uname;
      // this..full_name = row.full_name;
      // this..mobile = row.mobile;
      // this..identity_number = row.identity_number;
      // this..status = row.status;
      // this..is_black = row.is_black;
      // this..is_leader = row.is_leader;
      // this..password = '';
      // console.log(row)
      this.addOrderDialogFormVisible = true;
    },

    //执行新增订单
    doAddOrder(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          // this.addOrderFormData.pay_time = this.myfun.utc2beijing(this.addOrderFormData.pay_time);
          this.myfun.request(
              "adminOrderAddTestOrder.api",
              this.addOrderFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.addOrderDialogFormVisible = false;
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getNetLine() {
      this.showParentNet = false;
      this.recommentLineList = [];
      if (this.parentFormData.keywords !== "") {
        this.myfun.request(
          "adminMemberGetNetParentLineList.api",
          {
            searchField: this.parentFormData.searchField,
            keywords: this.parentFormData.keywords,
          },
          "post"
        )
          .then((res) => {
            if (res.code === "1") {
              this.parentData = res.data;
              this.showParentLine = true;
            }
          });
      }
    },
    getRecommentLine() {
      this.showRecommendNet = false;

      this.recommentLineList = [];
      if (this.recommendFormData.keywords !== "") {
        this.myfun.request(
            "adminMemberGetRecommendParentsLineList.api",
            {
              searchField: this.recommendFormData.searchField,
              keywords: this.recommendFormData.keywords,
            },
            "post"
          )
          .then((res) => {
            if (res.code == "1") {
              this.recommentData = res.data;
              this.showRecommendLine = true;
            }
          });
      }
    },
    toUrl(command){
      myfun.myRouterAutoPush(command)
    },
    mytest() {
      console.log("安置debug:");
      console.log(this.parentFormData);
      console.log("推荐debug:");
      console.log(this.recommendFormData);
    },
    toSearch(type, uname){
      if(uname === '无'){
        // this.$message.warning();
        return;
      }
      if(type === 1){
        this.recommendFormData.keywords = uname
        this.onSubmit('recommendFormData')
      }else if(type === 2){
        this.parentFormData.keywords = uname
        this.onSubmit('parentFormData')
      }
    }
  },
  created() {
    // let date = new Date();
    // this.addOrderFormData.pay_time=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    // console.log(this.addOrderFormData.pay_time);
    // console.log('aaa',date);
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.wangluogreen {
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.wangluohui {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluored {
  padding: 5px 10px;
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluoyellow {
  padding: 5px 10px;
  color: #0099CC;
  background: #99CCFF;
  border-color: #fbc4c4;
  border-radius: 4px;
  margin: 0 10px;
}
.wangluo {
  padding: 5px 10px;
  background: #ecf5ff;
  color: #409eff;
  border-color: #b3d8ff;
  border-radius: 4px;
  margin: 0 10px;
}
.el-tree-node__content {
  height: 50px !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  min-height: 800px;
}

.lineList {
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
}
.lineList li {
  list-style: none;
  padding: 10px 5px;
  font-size: 14px;
}
.lineList li:nth-child(n + 3):before {
  content: " > ";
}
.el-form-item {
  margin-bottom: 0;
}
.el-table th,
.el-table tr {
  background-color: transparent !important;
}
</style>
