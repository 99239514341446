<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
      >
<!--        <el-form-item>-->
<!--          <el-select-->
<!--            style="width: 140px"-->
<!--            v-model="formData.statusValue"-->
<!--            placeholder="状态查询"-->
<!--          >-->
<!--            <el-option label="状态查询" value=""></el-option>-->
<!--            <el-option label="限登录" value="restricted_login"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 120px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="真实姓名" value="4">真实姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
<!--    <el-button-->
<!--      style="width: 100px; margin-top: 20px"-->
<!--      @click="statusManage(1)"-->
<!--      v-if="myfun.checkAuthRule('adminMemberBitchVip')"-->
<!--      :disabled="multipleSelection.length < 1"-->
<!--    >-->
<!--      批量维护-->
<!--    </el-button>-->
    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
<!--      <el-table-column-->
<!--        type="selection"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--        width="40"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
        prop="id"
        label="#ID"
        width="100"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="member_info"
        label="会员信息"
        width="400"
        header-align="center"
      >
        <template slot-scope="scope">
          <span
            v-html="
              '会员编号：' + scope.row.uname + ' <br> ' +
              '姓名：' +
              scope.row.full_name +
              ' <br> ' +
              '手机号码：' +
              scope.row.mobile
            "
          ></span>
          <!-- <span
            v-if="scope.row.member_level === '3'"
            v-html="
              '会员编号：' +
              scope.row.uname +
              '<br />' +
              '真实姓名：' +
              scope.row.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.identity_number +
              '<br />' +
              '领导人：' +
              scope.row.leader_info.uname +
              ' ' +
              scope.row.leader_info.full_name
            "
          ></span> -->
        </template>
      </el-table-column>
      <el-table-column
          prop="recommend_info"
          label="推荐人"
          width="180"
          align="center"
          header-align="center"
      >
        <template slot-scope="scope">
          <span
              v-if="scope.row.recommend_info !== ''"
              v-html="
              scope.row.recommend_info.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.recommend_info.uname
            "
          ></span>
          <span v-else v-html="'-'"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="last_time"
        label="最后登录时间"
        width="200"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="注册时间"
        width="200"
        align="center"
        header-align="center"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="status_str"-->
<!--        label="状态"-->
<!--        width="200"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch-->
<!--            :disabled="!myfun.checkAuthRule('adminMemberEditMember')"-->
<!--            class="switch custom"-->
<!--            v-model="scope.row.restricted_login"-->
<!--            :active-value="'1'"-->
<!--            :inactive-value="'0'"-->
<!--            active-text="限制登录"-->
<!--            inactive-text="不限登录"-->
<!--            active-color="#F0413E"-->
<!--            @change="-->
<!--              (val) => {-->
<!--                changeMemberStatus(val, scope.row.id, 'restricted_login');-->
<!--              }-->
<!--            "-->
<!--          ></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        header-align="center"
      >
        <template class="annniuss" slot-scope="scope">
          <el-button
            @click="editMember(scope.row)"
            type="primary"
            size="small"
            plain
            style="margin: 2px 5px"
            v-if="myfun.checkAuthRule('adminMemberEditMember1')"
            >编辑会员</el-button
          >
          <el-button
            style="margin: 2px 5px"
            @click="editNet(scope.row, 1)"
            type="warning"
            plain
            size="small"
            v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend')"
            >改推荐人</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
      :title="operateTitle"
      :visible.sync="editNetdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="editNetformData"
        :rules="checkEditNetformRules"
        ref="editNetformData"
      >
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="">
          <div style="text-align: left">
            <el-input
              :value="editNetformData.full_name"
              readonly="true"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          label="推荐人编号"
          :label-width="formLabelWidth"
          prop="recommend_uname"
          v-show="editNetformData.type === 1"
        >
          <el-input
            v-model="editNetformData.recommend_uname"
            autocomplete="off"
          ></el-input>
          <span class="help-block">
            <i class="el-icon-warning"></i>清空则为删除
          </span>
        </el-form-item>
        <el-form-item
          label="安置人编号"
          :label-width="formLabelWidth"
          prop="parent_uname"
          v-show="editNetformData.type === 2"
          required
        >
          <el-input
            v-model="editNetformData.parent_uname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="安置位置"
          :label-width="formLabelWidth"
          prop="net_postition"
          style="text-align: left"
          v-show="editNetformData.type === 2"
          required
        >
          <el-radio-group v-model="editNetformData.net_postition">
            <el-radio label="左"></el-radio>
            <el-radio label="右"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
          <el-input
            v-model="editNetformData.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editNetdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('editNetformData')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- Form -->

    <el-dialog
      title="编辑会员"
      :visible.sync="editMemberdialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="huiyuanxinxi"
        :model="editMemberformData"
        :rules="checkEditMemberformRules"
        ref="editMemberformData"
      >
        <el-form-item
          label="昵称"
          :label-width="formLabelWidth"
          prop="nickname"
          required
        >
          <el-input
            v-model="editMemberformData.nickname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          :label-width="formLabelWidth"
          prop="full_name"
          required
        >
          <el-input
            v-model="editMemberformData.full_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="手机号"
          :label-width="formLabelWidth"
          prop="mobile"
          required
        >
          <el-input
            v-model="editMemberformData.mobile"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="身份证号"
          :label-width="formLabelWidth"
          prop="identity_number"
          v-show="editMemberformData.member_level === '3'"
        >
          <el-input
            v-model="editMemberformData.identity_number"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="是否领导人"
          :label-width="formLabelWidth"
          prop="is_leader"
          style="text-align: left"
          v-show="editMemberformData.member_level === '3'"
        >
          <el-radio-group v-model="editMemberformData.is_leader">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item
          label="密码"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            v-model="editMemberformData.password"
            placeholder="不修改密码留空即可"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editMemberdialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="doEditMember('editMemberformData')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 批量维护 -->
    <el-dialog
      title="批量维护"
      :model="bitchFormData"
      :visible.sync="batch_manage"
      v-loading="loading"
    >
      <el-form ref="batch_manage">
        <el-form-item
          label="选中示例"
          :label-width="formLabelWidth"
          v-show="false"
        >
          <el-tag class="batch_manage_tags"> (直销商)会员编号，姓名 </el-tag>
          <el-tag type="success" class="batch_manage_tags">
            (VIP)手机号，姓名
          </el-tag>
        </el-form-item>
        <el-form-item
          label="当前选中"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 1"
        >
          <el-tag
            :type="item.member_level == '3' ? '' : 'success'"
            v-for="(item, index) in multipleSelection"
            :key="index"
            class="batch_manage_tags"
            :closable="multipleSelection.length > 1"
            :disable-transitions="true"
            @close="handleClose(item)"
          >
            <span v-if="item.member_level == '1'">
              {{ item.mobile }},{{ item.full_name }}
            </span>
            <span v-if="item.member_level == '3'">
              {{ item.uname }},{{ item.full_name }}
            </span>
          </el-tag>
        </el-form-item>
        <el-form-item
          label="会员编号"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          {{ bitchFormData.uname }}
        </el-form-item>
        <el-form-item
          label="范围规则"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          <el-select
            class="el-select-smid"
            v-model="bitchFormData.subtype"
            placeholder="请选择"
          >
            <el-option label="推荐网下全部" value="1"></el-option>
            <el-option label="安置网下全部" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="其他"
          :label-width="formLabelWidth"
          v-show="bitchFormData.method == 2"
        >
          <el-checkbox v-model="bitchFormData.isself"> 包含自身 </el-checkbox>
        </el-form-item>
        <el-form-item label="状态操作" :label-width="formLabelWidth">
          <el-switch
            class="switch custom batch_manage_switch"
            v-model="bitchFormData.restricted_login"
            :active-value="'1'"
            :inactive-value="'0'"
            active-text="限制登录"
            inactive-text="不限登录"
            active-color="#F0413E"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batch_manage = false">取 消</el-button>
        <el-button type="primary" @click="doBatchManage()"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MemberList",
  components: {},
  data() {
    return {
      test: true,
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "2",
        statusType: "1",
        statusValue: [],
      },
      editNetformData: {
        id: "0",
        uname: "",
        full_name: "",
        recommend_uname: "",
        parent_uname: "",
        net_postition: "左",
        type: "",
        member_level: "",
        remark: "",
      },
      editMemberformData: {
        id: "0",
        uname: "",
        full_name: "",
        nickname: "",
        mobile: "",
        identity_number: "",
        status: "",
        is_black: "",
        is_leader: "",
        password: "",
      },
      checkEditNetformRules: {
        parent_uname: [{ required: true, message: "请输入安置人编号" }],
      },
      checkEditMemberformRules: {
        nickname: [{ required: true, message: "请输入会员昵称" }],
        full_name: [{ required: true, message: "请输入会员姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      tableData: [],
      loading: false,
      operateTitle: "修改推荐人",
      formLabelWidth: "120px",
      editNetdialogFormVisible: false,
      editMemberdialogFormVisible: false,
      multipleSelection: [],
      batch_manage: false,
      bitchFormData: {
        method: 1,
        member_id: [],
        mobile: "",
        subtype: "",
        isself: false,
        restricted_login: 0,
        restricted_awards: 0,
        restricted_activation: 0,
        restricted_settlement: 0,
      },
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberGetVipList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    editNet(row, type) {
      this.operateTitle = type === 1 ? "修改推荐人" : "修改安置人";
      this.editNetformData.type = type;
      this.editNetformData.member_level = row.member_level;
      this.editNetformData.id = row.id;
      this.editNetformData.uname = row.uname;
      this.editNetformData.full_name = row.full_name;

      if (type === 1) {
        this.editNetformData.parent_uname = "111111";
        this.editNetformData.net_postition = "左";
      } else if (type === 2) {
        this.editNetformData.recommend_uname = "111111";
      }

      if (row.recommend_info !== "") {
        this.editNetformData.recommend_uname = row.recommend_info.uname;
      }

      if (row.parent_info !== "") {
        this.editNetformData.parent_uname = row.parent_info.uname;
        this.editNetformData.net_postition = row.net_postition_str;
      }

      this.editNetdialogFormVisible = true;
    },
    editMember(row) {
      this.editMemberformData.id = row.id;
      this.editMemberformData.member_level = row.member_level;
      this.editMemberformData.uname = row.uname;
      this.editMemberformData.full_name = row.full_name;
      this.editMemberformData.nickname = row.nickname;
      this.editMemberformData.mobile = row.mobile;
      this.editMemberformData.identity_number = row.identity_number;
      this.editMemberformData.is_leader = row.is_leader;
      this.editMemberformData.password = "";
      this.editMemberdialogFormVisible = true;
    },
    doEditMember(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun.request(
              "adminMemberEditMember.api",
              this.editMemberformData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.editMemberdialogFormVisible = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName =
            this.editNetformData.type === 1
              ? "adminMemberEditMemberRecommend.api"
              : "adminMemberEditMemberParent.api";
          this.myfun.request(apiName, this.editNetformData)
            .then((result) => {
              if (result.code === "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                } else {
            this.loading = false;
          }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
    changeMemberStatus(value, id, index) {
      //验证通过执行请求
      this.loading = true;
      this.myfun.request(
          "adminMemberEditMember.api",
          {
            id: id,
            statusIndex: index,
            status: value,
            isModifyMemberStatus: 1,
          },
          "post"
        )
        .then((result) => {

          this.loading = false;
        });
    },
    statusManage(method = 1, row = {}) {
      this.bitchFormData.method = method;
      this.batch_manage = 1;
      if (this.bitchFormData.method == 2) {
        this.bitchFormData.mobile = row.mobile;
      }
    },
    doBatchManage() {
      if (this.bitchFormData.method == 1) {
        //批量复选
        if (this.multipleSelection.length < 1) {
          this.$message.warning("请选择要修改的会员");
        } else {
          let member_id = [];
          this.multipleSelection.forEach((item, key) => {
            member_id.push(item.id);
          });
          this.bitchFormData.member_id = member_id;
          //验证通过执行请求
          this.loading = true;
          this.myfun.request("adminMemberBitchMember.api", this.bitchFormData)
            .then((result) => {
              if (result.code === "1") {
              this.getList();
              this.batch_manage = 0;
            } else {
            this.loading = false;
          }
            });
        }
      } else if (this.bitchFormData.method == "2") {
        if (this.bitchFormData.uname == "") {
          this.$message.warning("请填写会员编号");
          return;
        }
        if (this.bitchFormData.subtype == "") {
          this.$message.warning("请选择范围规则");
          return;
        }
        this.loading = true;
        this.myfun.request("adminMemberBitchMember.api", this.bitchFormData)
          .then((result) => {
            if (result.code === "1") {
              this.getList();
              this.batch_manage = 0;
            } else {
            this.loading = false;
          }
          });
      }
    },
  },
  created() {
    this.getList(1);
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.annniuss .el-button {
  float: left;
}
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.switshsdf {
  display: flex;
  flex-direction: column;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}

/* switch按钮样式 */
.el-switch {
  margin: 8px;
}
.switch.custom .el-switch__label--left span {
  margin-right: 5px;
}
.switch.custom .el-switch__label--right span {
  margin-left: 5px !important;
  margin-right: 20px !important;
}
.switch.custom .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch.custom .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch.custom .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch.custom .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch.custom .el-switch__label--left span {
  margin-left: 20px;
}
/*显示文字*/
.switch.custom .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.custom.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 82px !important;
  margin: 0;
}
.batch_manage_tags {
  margin-right: 10px;
}
.batch_manage_switch {
  margin-right: 10px;
}
.help-block {
  font-size: 13px;
  color: #e6a23c;
}
</style>
